
document.addEventListener("turbolinks:load", () => {
	if($('div.payPalCheckout').length > 0) {
		paypal.Buttons({
      createOrder: function(data, actions) {   
        var order_total = get_total() + '';
        var order_obj = {
          purchase_units: [{
            amount: {
              value: order_total,
              currency_code: 'EUR',
              breakdown: {
                item_total: {
                  currency_code: 'EUR',
                  value: order_total
                }
              }
            },
            items: [{
              name: $('#license_license_type_name').val(),
              unit_amount: {
                value: $('#license_license_type_price').val(),
                currency_code: 'EUR'                
              },
              quantity: $("#license_quantity").val()
            }]
          }]
        }
        // console.log(order_obj);
        return actions.order.create(order_obj)
      },
      onApprove: function(data, actions) {
        // Capture the funds from the transaction
        return actions.order.capture().then(function(details) {
          // Show a success message to your buyer
          $('form #license_paypal_id').val(details.id);
          $('form').submit();
        });
      }
    }).render('.payPalCheckout');
	}
})

window.update_total = function(){
	$('#order_total').html(get_total('html'));
}

window.get_total = function(format='plain'){
	var license_type = $("#license_license_type_id").val();
	var quantity = $("#license_quantity").val();
	var total;
	$.ajax({
		url: '/get_price',
		data: {license_type_id: license_type, quantity: quantity},
		dataType: 'json',
		async: false
	}).done(function(data){
		switch(format) {
			case 'html':
				total = data['price_html']
				break;
			default:
				total = data['price']
		}
	});

	return total;
}
