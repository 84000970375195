//import "../stylesheets/swatchy"
import * as SwatchyModule from './swatchy.js'

document.addEventListener("turbolinks:load", () => {
  // Copy Url button
  var $temp = $("<input>");
  var $url = $(location).attr('href');
  $('.copy-url').on('click', function(){
    $("body").append($temp);
    $temp.val($url).select();
    document.execCommand("copy");
    $temp.remove();
  });

  // Model filters

	$('form.model_filters').on('click', '.clear_filters', function(e) {
		$('form.model_filters input[type="number"]').val('');
		$('form.model_filters input[type="checkbox"]').prop('checked', false);
		//e.preventDefault();
	});

  $('form.model_filters').on('change', 'input[name^="filter"]', function(e) {
    $('form.model_filters').trigger("submit");
  });

  // console.log("require filters");
  // $('form.model_filters').on('submit', function(e){
  //   // both apply and clear filters use submit. 
  //   // we need to check which button fired to prevent default only for the apply button
  //   if(e.originalEvent && !e.originalEvent.submitter.classList.contains('clear_filters')){
  //     if(requiredFilled() !== true){
  //       $('.required_filters_msg').addClass('visible');        
  //       e.preventDefault();
  //     }
  //   }
  // });

  $('form.model_filters').on('change', 'select[name^="language"]', clearAllFilters);

  $('div.filter_by_type').on('click', 'a', clearAllFilters)

   // Delete attachment file
  $('form').on('click', '.destroy_attachment', function(event){
    console.log('Delete attachment');
    checked = $(this).prop('checked');
    list_item = $(this).parent().parent().children("td").first();
    if(checked){
      list_item.css('text-decoration', 'line-through');
      list_item.addClass('destroy');
    }
    else {
      list_item.css('text-decoration', 'none');
      list_item.removeClass('destroy');
    }
  });

  $('form').on('submit', function(){
    $('table.attachments td.destroy').each(function(){
      $(this).children('input[type="hidden"]').remove();
    });
  });

  $('.swatchy-picker').each(function(){
    makeDivIntoSwatch($(this));
  });

  $('.da_timer').each(function(){
    startProgressBar($(this), 60000);
  });

});

// function requiredFilled(e = null){  
//   var filled = true;
//   var submit_button = $('form.model_filters').find('input[type=submit]');
//   var required_filters = $('.filter_label.required').parent().toArray();
//   var unset_filters = [];

//   if(required_filters.length == 0){
//     return true;
//   }

//   if(e){
//     var submiter_name = $(e.target).parent().parent().parent().find('h6').text();
//     if(submiter_name.includes("Zastosowanie") || submiter_name.includes("Application")){
//       $(e.target).parent().parent().find('input[type="checkbox"]').each(function(){ 
//         $(this).prop("checked", false); 
//       });
//       $(e.target).prop("checked", !$(e.target).prop("checked"));
//       var selected_text = $(e.target).parent().text();
//       if(selected_text.includes("dach") || selected_text.includes("roof")){
//         $('h6:contains("Układ montażu na ścianie"), h6:contains("Wall mounting")').each(function(){
//           $(this).parent().hide();
//           $(this).parent().find('input[type="checkbox"]').each(function(){ $(this).prop("checked", true)});
//         });
//       }
//       else {
//         $('h6:contains("Układ montażu na ścianie"), h6:contains("Wall mounting")').each(function(){
//           $(this).parent().show();
//           $(this).parent().find('input[type="checkbox"]').each(function(){ $(this).prop("checked", false)});
//         });
//       }
//     }
//   }
//   else {
//     if($('.required ul li:contains("roof"), .required ul li:contains("dach")')
//           .first()
//           .find('input[type="checkbox"]')
//           .prop("checked"))
//     {
//       $('h6:contains("Układ montażu na ścianie"), h6:contains("Wall mounting")').each(function(){
//         $(this).parent().hide();
//         $(this).parent().find('input[type="checkbox"]').each(function(){ $(this).prop("checked", true)});
//       });
//     }
//   }
//   required_filters.forEach(filter => {
//     var checked = $(filter).find('input[type=checkbox]:checked');
//     var filled_textfields = [];
//     $(filter).find('input[type=number]').each(function(){
//       if($(this).val()){
//         filled_textfields.append($(this));
//       }
//     });
//     if(checked.length == 0 && filled_textfields.length == 0){
//       filled = false;
//       unset_filters.push($(filter).find('h6').text());
//     }
//   });
  
//   if(filled === true){
//     submit_button.removeClass('disabled');    
//     $('.required_filters_msg').removeClass('visible'); 
//   }
//   else {
//     $('#unset_required_filters').children().remove();
//     unset_filters.forEach(function(filter_name){
//       $('#unset_required_filters').append('<li>' + filter_name + '</li>');
//     });
//     submit_button.addClass('disabled');
//   }
  
//   return filled;
// }

function clearAllFilters(event) {
  let newHref = window.location.origin + window.location.pathname
  let urlQuery = new URLSearchParams(window.location.search)
  let newUrlQuery = new URLSearchParams("?")
  for(const [key,value] of urlQuery.entries())
  {
    if(key.includes("filter"))
      continue

    newUrlQuery.append(key,value);
  }
  let lanuageSelectBox = document.getElementById("language")
  newUrlQuery.set('language', lanuageSelectBox.value)
  newHref += "?" + newUrlQuery.toString()
  window.open(newHref, "_self")
}

/**
 * 
 * @param {Array} swatchContainer - jQuery object array containing only the target 'div' element.
 */
function makeDivIntoSwatch(swatchContainer) {
  if (swatchContainer && swatchContainer.length >= 1) {
    let colorArray = $(swatchContainer).data('color-array');
    if (colorArray && colorArray.length >= 1) {
      SwatchyModule.SingleSwatchy(swatchContainer[0].id, colorArray, true);
    } else {
      console.error(`Color data is empty for div id: ${swatchContainer[0].id}`);
    }
  }else{
    console.error(`Div doesn't exists! ${swatchContainer}`);
  }
}

function startProgressBar(progressBar, duration) {
  var start = null;

  function step(timestamp) {
    if (!start) start = timestamp;
    var progress = timestamp - start;
    progressBar.css('width', Math.min((progress / duration) * 100, 100) + '%')
    if (progress < duration) {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
}


