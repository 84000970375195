//Vanilla js document.ready equivalent. Probaly doesnt work on IE<10, but who cares anymore
function ready(fn) {
	if (document.readyState !== 'loading') {
	  fn();
	  return;
	}
	document.addEventListener('DOMContentLoaded', fn);
  };

function oneLineTag(tag,options){
	return Object.assign(document.createElement(tag),options);
  }

export {ready, oneLineTag} 

