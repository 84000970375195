// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")

require("packs/license_form.js")
require("packs/models")
require("packs/reports")
require("packs/users")
require("chartkick")
require("chart.js")

require("trix")
require("@rails/actiontext")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import "bootstrap"
import "../stylesheets/application"

// Datepicker
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
flatpickr(".datepicker", {})


document.addEventListener("turbolinks:load", () => {
	// $('[data-toggle="tooltip"]').tooltip()
	// $('[data-toggle="popover"]').popover(
})

import "controllers"

//Design automation
require("packs/design_automation")

import * as Routes from '../routes';
window.Routes = Routes;

