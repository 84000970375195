params = '?'

document.addEventListener("turbolinks:load", () => {
    // $('.input-daterange input').each(() => {
    //     $(this).datepicker({
    //       format: 'yyyy-mm-dd'
    //     })
    //   }
    // )

  
    $('form.user_export input.filter, form.user_export select.filter').change( () => {
        apply_filters();
      }
    )

    $('form.user_export input#select_all').change( () => {
        var checked = $('input#select_all').prop('checked');
        $('input[name^="users"]').prop('checked', checked);
      }
    )

    $('form.user_export input[type="submit"]').click( () => {
      if($('input[name^="users"]:checked').length == 0) {
        alert('Please select at least one user');
        return false;
        }
      }
    )

    $('form.user_export').submit( () => {
        Turbolinks.visit(location.toString());
      }
    ) 
  }
)

apply_filters = () => {
  status =  $('select[name="status"]').val();
  params += 'status=' + status;

  since = $('input#since').val();
  if (since != ''){
    params += '&amp;since=' + since;
  }

  to = $('input#to').val();
  if (to != '') {
    params += '&amp;to=' + to;
  }

  window.location.href = window.location.origin + window.location.pathname + params;
}